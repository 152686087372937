@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-toastify/dist/ReactToastify.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(165, 165, 165);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6b6b6b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2c3744;
}
